<template>
  <div id="ap">
    <a-layout>
      <div class="content-header">
        <a-affix :offset-top="0" style="width: 100%;">
          <a-row type="flex" justify="space-between">
            <a-col span="12">
              <common-page-btn @add="$refs.orderForm._show()">
               
              </common-page-btn>
            </a-col>
            <a-col span="12">
              <a-row type="flex" align="middle" justify="end" :gutter="3">
                <a-col span="12">
                  <a-input v-model="param.keyword" allowClear search @pressEnter="getList" @on-clear="getList"
                           placeholder="关键词查询"></a-input>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-affix>
      </div>
    </a-layout>

    <a-layout>
      <a-layout-content>
        <a-table :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25"
                 :style="{ background: '#FFF' }" size="small" row-key="orderId" border :columns="keys" ref="list"
                 :loading="loading" :data-source="data.records">
          <!-- 状态 -->
          <template slot-scope="row" slot="status">
            <a-tooltip title="待审批">
              <a-icon v-if="row.status == '待审批'" type="question-circle" />
            </a-tooltip>
            <a-tooltip :title="`未批准：${row.cause}`">
              <a-icon v-if="row.status == '未批准'" style="color:red" type="stop" />
            </a-tooltip>
            <a-tooltip title="已批准"><a-icon style="color:#090" v-if="row.status == '已批准'"
                      type="check-circle" /></a-tooltip>
          </template>
          <!--操作-->
          <template slot-scope="row" slot="action">
            <a-button-group shape="circle" size="small" v-has="'integral:order:examine'">
              <a-tooltip v-if="row.orderStatus == '待审核'" title="审批"><a-button type="link" icon="check"
                          @click="showModal(row)"></a-button></a-tooltip>
            </a-button-group>
          </template>
        </a-table>
      </a-layout-content>
    </a-layout>
    <a-row>
      <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
        <a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext" @showSizeChange="setLimit"
                      :current="data.current" />
      </a-col>
    </a-row>

    <!-- <record-form ref="recordForm" @success="doRefresh"></record-form> -->
    <div>
      <a-modal title="审批" cancelText="取消审批" okText="确认审批" :visible="visible" :confirm-loading="confirmLoading"
               @ok="handleOk" @cancel="handleCancel">
        <a-form-model>
          <a-row :gutter="15">
            <a-col span="8">
              <a-form-model-item label="审批结果" label-position="top">
                <a-switch v-model="isExamine" checked-children="通过" un-checked-children="不通过" />
              </a-form-model-item>
            </a-col>
            <a-col span="16">
              <a-form-model-item label="不通过原因" label-position="top" :required="!isExamine">
                <a-input v-model="cause" :disabled="isExamine" placeholder="请输入不通过原因"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </div>
    <order-form @success="getList()" ref="orderForm"></order-form>
  </div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import utils from '@/common/utils';
import orderForm from './components/orderForm.vue';
import apiUtils from '@/common/apiUtil';
export default {
  components: { orderForm },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      loading: false,
      data: [],
      isExamine: false,
      cause: '',
      applyId: null,
      organList: apiUtil.getOrganList(),
      depList: [],
      param: {
        page: 1,
        limit: 20,
        organId: apiUtil.getOrganId(''),
        keyword: ''
      },
      keys: [
        { title: '商品名称', dataIndex: 'productName', width: 200, ellipsis: true, align: 'center' },
        { title: '申请人', dataIndex: 'userName', width: 160, ellipsis: true },
        { title: '积分', dataIndex: 'total', ellipsis: true },
        { title: '申请时间', dataIndex: 'creationTime', width: 160, align: 'center', ellipsis: true },
        { title: '状态', dataIndex: 'orderStatus', width: 160, align: 'center', ellipsis: true },
        { title: '操作员', dataIndex: 'staffName', width: 160, align: 'center', ellipsis: true },
        { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
      ]
    };
  },

  created() {
    this.getList();
  },
  methods: {
    ...apiUtil,
    doRefresh() {
      // console.log('成功');
      this.param.page = 1;
      this.getList();
    },
    // 获取数据
    getList() {
      this.loading = true;
      this.http
        .get('/platform/product/order/list', this.param)
        .then(ret => {
          this.data = ret.data;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
        });
    },
    //设置每页的显示条数
    setLimit(current, limit) {
      this.param.page = 1;
      this.param.limit = limit;
      this.getList();
    },
    //页码切换
    getNext(page) {
      this.param.page = page;
      this.getList();
    },
    // 点击审批
    showModal(e) {
      // console.log(e);
      this.orderId = e.orderId;
      this.cause = '';
      if (e.cause == '待审批' || e.cause == '未批准') {
        this.isExamine = false;
      } else {
        this.isExamine = true;
      }
      this.visible = true;
    },
    // 确认审批
    handleOk() {
      console.log(this.isExamine);
      let a = 0;
      if (this.isExamine) {
        a = 1;
      } else {
        a = -1;
      }
      this.http
        .post('/platform/product/order/update', { staffId: apiUtils.getUser('staffId'), orderId: this.orderId, orderStatuse: a })
        .then(ret => {
          if (ret.code == 200) {
            this.utils.success('操作成功！').then(() => {
              this.getList();
            });
          }
          this.visible = false;
        })
        .catch(err => {
          this.visible = false;
        });
    },
    // 取消审批
    handleCancel(e) {
      this.visible = false;
    }
  }
};
</script>

<style scoped></style>
