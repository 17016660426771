<template>
  <a-modal v-model="show" title="物品兑换" :width="700" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
    <a-form-model ref="formRef" :model="formData" :rules="formRule">
      <a-row :gutter="15">
        <a-col span="6" v-if="getOrganId() == 0">
          <a-form-model-item label="单位" prop="organId">
            <a-select v-model="formData.organId" placeholder="请选择单位" @select="selectorganId">
              <a-select-option value="">所有单位</a-select-option>
              <a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{
                organ.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col span="8">
          <a-form-model-item label="请选择物品" label-position="top">
            <select-goods @confirm="confirm"></select-goods>
          </a-form-model-item>
        </a-col>
        <a-col span="8">
          <a-form-model-item label="请输入兑换数量" label-position="top">
           <a-input-number :min="1" v-model="formData.number"></a-input-number>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-spin size="large" fix v-if="loading" />
  </a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import selectGoods from './selectGoods.vue';
export default {
  components: { selectGoods },
  data() {
    return {
      show: false,
      loading: false,
      organList: apiUtil.getOrganList(),
      CarList: [],
      AssignList: [],
      staffList: [],
      formData: {
        organId: apiUtil.getOrganId(),
        productId: '',
        productName: '',
        pointsRequired: '',
        userId: apiUtil.getUser('staffId'),
        number: 1
      },
      formRule: {

      }
    };
  },
  methods: {
    ...apiUtil,
    confirm(e) {
      // console.log(e);
      this.formData.productId = e[0].productId;
      this.formData.productName = e[0].productName;
      this.formData.pointsRequired = e[0].pointsRequired;
      // this.formData.productId = e[0].productId;
    },
    _show(form) {
      this.show = true;
      // this.$nextTick(() => {
      //   this.$refs.formRef.resetFields();
      //   if (form) {
      //     this.formData = JSON.parse(JSON.stringify(form));
      //   } else {
      //     this.formData.organId = apiUtil.getOrganId('');
      //   }
      // });
    },
    _close() {
      this.$refs.formRef.resetFields();
      this.show = false;
      this.$emit('close');
    },
    doSubmit() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          utils.showSpin();
          request.post('/platform/product/order/save', this.formData).then(ret => {
            // console.log(ret)
            this.show = false;
            if (ret.code == 200) {
              utils.success('操作成功！').then(() => {
                // this.$refs.formRef.resetFields();
                this.show = false;
                this.$emit('success');
              });
            }
          });
        }
      });
    },
    // 选择单位时
    selectorganId(e) {
      this.formData.name = '';
      this.formData.carId = '';
      this.getstaffList(e);
    },
    // 获取员工信息
    getstaffList(organId, id) {
      this.http
        .get('/platform/staff/list', { organId: organId, depId: id })
        .then(ret => {
          console.log(ret.data);
          this.staffList = ret.data.records;
        })
        .catch(err => { });
    },

  }
};
</script>

<style scoped>
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
</style>
